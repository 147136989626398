.howToDoBox {
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
}

.howToDo__heading {
  text-align: center;
  line-height: 20px;
  padding-bottom: 25px;
  font-weight: 500;
  font-size: 16px;
}

.howToDo__subHeading {
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 20px 5px 30px;
}

.howToDo__content {
  font-weight: 400;
  font-size: 14px;
  padding: 0 20px 20px 30px;
}

.howToDo__serialNo {
  position: absolute;
  left: 0;
  font-weight: 700;
  font-size: 40px;
  color: rgba(117, 117, 117, 1);
}

.howToDo__serialNo-1 {
  top: 10%;
}
.howToDo__serialNo-2 {
  top: 35%;
}
.howToDo__serialNo-3 {
  top: 60%;
}
.howToDo__serialNo-3 {
  top: 60%;
}
.orderDetails__container {
  background-color: #fff;
  width: 96%;
  margin: 25px 20px 20px 20px;
  padding: 40px;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
  margin-left: 8px;
}
.edit-tag {
  user-select: none;
  margin-left: 8px;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
  margin-left: 8px;
}

.orderDetails__container .ant-upload-list-text-container {
  max-width: 160px;
}

/*
 * Media Queries
 *
 */

/* Smallsize screen ~ mobile screen */
@media (max-width: 768px) {
  .orderDetails__container {
    background-color: #fff;
    padding: 40px;
  }
}
/*
 *
 *
 */

/***Order Details***/

.orderBox {
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
}

.orderDetail__heading {
  color: grey;
}

.orderDetail__heading p {
  line-height: 20px;
  margin-right: 50px;
}

.orderDetail__link {
  text-decoration: underline !important;
  padding-left: 10px;
}

.orderDetail {
  padding-bottom: 15px;
}
.detail__item {
  padding-bottom: 5px;
}

.detail__item:last-child {
  padding-bottom: 15px;
}
