.profile__container {
    background-color: white;
    margin: 20px;
    padding: 20px;
    min-height: 90vh;
    width: 100%;
    position: relative;
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.profile__avatar {
    padding-bottom: 20px;
}

.profile__avatar-box {
    top: 10%;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__container .ant-upload-list-text-container {
    max-width: 120px;
}

/*
 * Media Queries
 *
 */

/* Medium size screen ~ 14 inch laptop */
@media (max-width: 1280px) {
    .profile__container {
        min-height: 84vh;
    }
}
/*
 *
 *
 */
/* Smallsize screen ~ mobile screen */
@media (max-width: 768px) {
    .profile__container {
        margin: 0;
    }
}
/*
 *
 *
 */
