.container {
  background-color: white;
  padding: 20px;
  min-height: 85vh;
  min-width: 100%;
}

.reviewTable__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
}

.reviewTable__header-title {
  display: flex;
  align-items: baseline;
  flex: 0 0 55%;
}

.reviewTable__header-left {
  display: flex;
  align-items: baseline;
  flex: 0 0 65%;
}
.reviewTable__header-right {
  display: flex;
  align-items: baseline;
  flex: 0 0 10%;
}

.reviewTable__header-text {
  flex: 0 0 20%;
  padding: 0 10px;
}

.reviewTable__header-filter {
  padding: 0 5px;
}

.reviewTable__header-search {
  padding-top: 10px;
  flex: 0 0 30%;
}

.review__plan {
  font-size: 12px;
  line-height: 22px;
  color: #757575;
  padding-bottom: 5px;
}

.review__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
  text-align: left;
}
.review__title-position {
  color: #474747;
  font-weight: 600;
  text-align: left;
}

.review__info {
  display: flex;
  align-items: baseline;
  margin: 5px 10px 5px 0px;
}

.review__info-username {
  font-size: 12px;
  line-height: 22px;
  color: #474747;
  font-style: italic;
  padding: 0 20px 0 10px;
}

.review__info-experience {
  font-size: 12px;
  line-height: 22px;
  color: #474747;
  font-style: italic;
  padding-left: 10px;
}

.status__box {
  display: flex;
  align-items: center;
}

.status {
  position: relative;
}

.status__icon {
  display: flex;
  align-items: center;
  padding-left: 10px;
  position: relative;
  margin-top: 12px;
}

.statusDot {
  border-radius: 50%;
  color: #bfbfbf;
  width: 8px;
  height: 8px;
  background-color: #bfbfbf;
  margin: 2px;
  margin-left: 5px;
}
.statusLine {
  color: #bfbfbf;
  width: 98%;
  height: 2px;
  background-color: #bfbfbf;
  margin-top: -14.5px;
  top: 12px;
}

.statusDotBox {
  display: flex;
  align-items: center;
}

.statusDotBox__status {
  padding: 0 10px;
}

.status__message {
  display: inline-flex;
  padding-top: 4px;
}

.status__message-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  /* Sunset Orange/1 */

  background: #fff7e6;
  /* Sunset Orange/3 */

  border: 1px solid #ffd591;
  box-sizing: border-box;
  border-radius: 8px;
  color: #fa8c16;
}
.status__message-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  /* Sunset Orange/1 */

  background: #fafafa;
  /* Sunset Orange/3 */

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.65);
}
.status__message-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  /* Sunset Orange/1 */

  background: #e6f7ff;
  /* Sunset Orange/3 */

  border: 1px solid #91d5ff;
  box-sizing: border-box;
  border-radius: 8px;
  color: #1890ff;
}
.status__message-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  /* Sunset Orange/1 */

  background: #f6ffed;
  /* Sunset Orange/3 */

  border: 1px solid#B7EB8F;
  box-sizing: border-box;
  border-radius: 8px;
  color: #52c41a;
}

.spinner {
  margin: 20px 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.status__message-5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  /* Sunset Orange/1 */

  background: #f9f0ff;
  /* Sunset Orange/3 */

  border: 1px solid#D3ADF7;
  box-sizing: border-box;
  border-radius: 8px;
  color: #722ed1;
}

.statusWarning {
  color: rgba(255, 77, 79, 1);
}
.statusFinished {
  color: rgba(117, 117, 117, 1);
}

/*
 * Media Queries
 *
 */

/* Medium size screen ~ 14 inch laptop */
@media (max-width: 1280px) {
  .review__info-experience {
    font-size: 10px;
  }
  .review__info-username {
    font-size: 10px;
  }
}
/* Move Searchbar to next line */
@media (max-width: 1185px) {
  .reviewTable__header {
    flex-direction: column;
  }
  .reviewTable__header-title {
    justify-content: space-between;
    width: 100%;
  }
  .reviewTable__header-search {
    padding: 5px 0 10px 0;
    width: 100%;
  }
}
/* Move filters to next line */
@media (max-width: 646px) {
  .reviewTable__header-filter {
    padding: 0;
  }
}

/* Smallsize screen ~ mobile screen */
@media (max-width: 768px) {
  .reviewTable__header-title {
    flex-direction: column;
  }
  .reviewTable__header-right {
    width: 100%;
    justify-content: center;
  }
}
/*
 *
 *
 */
