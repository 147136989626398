.contentWrapper {
  min-height: 90vh;
  width: 100%;
}

.status {
  display: block;
}

.provideDetails__button {
  padding: 20px 0;
}

/*
 * Media Queries
 *
 */

/* Smallsize screen ~ mobile screen */
@media (max-width: 768px) {
  .contentWrapper {
    height: 100%;
    width: 100%;
  }
}
